<template>
  <div class="container-top">
    <div class="pc">
      <!-- banner -->
      <lp-banner img="page_thumb_scrap.png"
                 title="블로그"
                 sub-title="스타트업, 플랫폼 사업정보를 런치팩 스크랩을 통해서 만나보세요!"></lp-banner>
      <!-- board category -->
      <div class="flex-center board-cat-wrapper">
        <div class="board-cat-st subtitle5 unselect"
             :class="{'primary':cat.id===selectedBoardCat.id}"
             v-for="(cat, cat_idx) in board_cat_list"
             :key="`board-${cat_idx}`"
             @click="clickBoardCat(cat)">
          {{ cat.name }}
          <div :class="{'board-cat-selected':cat.id===selectedBoardCat.id}"></div>
        </div>
      </div>

      <div class="container">
        <!-- board  -->
        <!--<div class="board-wrapper">
          <div class="flex-align" v-if="selectedBoardCat.id">
            <div class="board-st subtitle4 unselect body2-medium"
                 :class="{'board-selected':board.id===selectedBoard.id}"
                 v-for="(board, board_idx) in board_list"
                 :key="`board-${board_idx}`"
                 @click="clickBoard(board)">{{ board.name }}</div>
          </div>
        </div>-->
        <div class="post-wrapper" style="margin-top:120px" v-if="post_list">
          <!-- post -->
          <div class="unselect"
               v-for="(post, post_idx) in post_list"
               :key="`post-${post_idx}`"
               @click="clickCard(post)">
            <div class="card-img" :style="imgField(post.img)"></div>
            <div class="subtitle4 sub margin-top-16">{{ post.title }}</div>
            <div class="body5 sub3 margin-top-8">
              <span>{{ datesFormat(post.created_time, 'date_3') }}</span>
              <span class="margin-left-20">{{ post.board_title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="post_list && post_list.length===0">
        <div class="subtitle4 sub3 padding-68 text-center">게시물이 없습니다.</div>
        <div class="lp-divider-gray1"></div>
      </div>
      <!-- 페이지네이션 -->
      <lp-paginate :filtered="postFiltered"
                   @getData="getBoardPost"
                   class="padding-top-80"
                   v-show="post_list && post_list.length>0"></lp-paginate>
    </div>

    <div class="mobile">
      <lp-banner img="page_thumb_scrap.png" device="mobile"
                 title="블로그"
                 sub-title="스타트업, 플랫폼 사업정보를 런치팩 스크랩을 통해서 만나보세요!"></lp-banner>
      <!-- board category -->
      <div class="board-cat-wrapper">
        <div class="flex-center" style="width:fit-content;margin:auto">
          <div class="board-cat-st subtitle7 unselect" :class="{'primary':cat.id===selectedBoardCat.id}"
               v-for="(cat, cat_idx) in board_cat_list" :key="`board-${cat_idx}`" @click="clickBoardCat(cat)">
            {{ cat.name }}
            <div :class="{'board-cat-selected':cat.id===selectedBoardCat.id}"></div>
          </div>
        </div>
      </div>

      <div class="container">
        <!-- board  -->
        <!--<div class="board-wrapper">
          <div class="flex-align" v-if="selectedBoardCat.id">
            <div class="board-st unselect body5-medium" :class="{'board-selected':board.id===selectedBoard.id}"
                 v-for="(board, board_idx) in board_list" :key="`board-${board_idx}`" @click="clickBoard(board)">{{ board.name }}</div>
          </div>
        </div>-->
        <div class="post-wrapper" style="margin-top:76px" v-if="post_list && post_list.length>0">
          <!-- post -->
          <div v-for="(post, post_idx) in post_list" :key="`post-${post_idx}`"
               @click="clickCard(post)">
            <div class="card-img" :style="imgField(post.img)"></div>
            <div class="subtitle7 sub margin-top-8 ellipsis-2">{{ post.title }}</div>
            <div class="body6 sub3 margin-top-4">
              <span>{{ datesFormat(post.created_time, 'date_3') }}</span>
              <span class="margin-left-20">{{ post.board_title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="post_list && post_list.length===0">
        <div class="body6 sub3 padding-28 text-center">게시물이 없습니다.</div>
        <div class="lp-divider-gray1"></div>
      </div>
      <!-- 페이지네이션 -->
      <lp-paginate :filtered="postFiltered"
                   @getData="getBoardPost"
                   class="margin-top-40"
                   v-show="post_list && post_list.length>0"></lp-paginate>
    </div>
  </div>
</template>
<script>
  import LpPaginate from "../component/LpPaginate";
  import LpBanner from "../component/LpBanner";
  export default {
    name: "Post",
    components: {LpBanner, LpPaginate},
    created() {
      this.getBoardCategory();
      this.getBoard();
      this.getBoardPost();
    },
    data() {
      return {
        board_cat_list: [],
        board_list: [],
        post_list: undefined,
        postFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        },
        selectedBoardCat: {name:'전체',id:''},
        selectedBoard: {name:'전체',id:''}
      }
    },
    methods: {
      clickCard(post) {
        this.routeGa('', `블로그 카드(${post.title})`, post.title);
        this.routerPush(`post/detail?id=${post.id}&board_id=${post.board}`, true);
      },
      clickBoardCat(cat) {
        this.selectedBoardCat = cat;
        this.selectedBoard = {name:'전체',id: ''};
        if(cat.id==='') {
          this.selectedBoardCat = {name:'전체',id: ''};
        }
        this.board_list = [];
        this.getBoard();
        this.getBoardPost();
      },
      clickBoard(board) {
        this.selectedBoard = board;
        if(board.id==='') {
          this.selectedBoard = {name:'전체',id: ''};
        }
        this.getBoardPost();
      },
      getBoardCategory() {
        this.$axios.get('public/board/category').then(res=>{
          if(res.data.length>0) {
            let list = [{name:'전체',id:''}];
            this.board_cat_list = list.concat(res.data);
          } else {
            this.board_cat_list = [];
          }
        })
      },
      getBoard() {
        let url = 'public/board';
        let params = {};
        if(this.selectedBoardCat.id) params.cat1 = this.selectedBoardCat.id;
        this.$axios.get(url, {
          params: params
        }).then(res=>{
          if(res.data.length>0) {
            let list = [{name:'전체',id:''}];
            this.board_list = list.concat(res.data);
          } else {
            this.board_list = [];
          }
        })
      },
      getBoardPost() {
        let url = '';
        if(this.selectedBoard.id) {
          url = `public/board/${this.selectedBoard.id}/post`;
        } else {
          url = 'public/board/post';
        }
        let params = this.cloneItem(this.postFiltered);
        if(this.selectedBoardCat.id) params.cat1 = this.selectedBoardCat.id;
        this.$axios.get(url, {
          params: params
        }).then(res=>{
          this.post_list = res.data.data;
          this.postFiltered.page_length = res.data.page_length;
          this.postFiltered.cur_page = res.data.cur_page;
          this.postFiltered.total_page = res.data.total_page;
          this.postFiltered.count = res.data.count;
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .board-cat-wrapper
    overflow-x scroll
    -ms-overflow-style none /* IE and Edge */
    scrollbar-width none /* Firefox */

  .board-cat-wrapper::-webkit-scrollbar
    display none /* Chrome, Safari, Opera*/

  .board-cat-st
    flex 0 0 auto
    position relative
    white-space nowrap

  .board-wrapper
    display flex
    justify-content center
    overflow-x scroll
    -ms-overflow-style none /* IE and Edge */
    scrollbar-width none /* Firefox */

  .board-wrapper::-webkit-scrollbar
    display none /* Chrome, Safari, Opera*/

  .pc
    .post-banner
      position relative
      width 100%
      height 250px
      background-color white

    .board-cat-wrapper
      border-bottom 1px solid $gray1


    .board-cat-st
      padding 20px 24px
      position relative

    .board-cat-selected
      position absolute
      left 0
      bottom 0
      width 100%
      background-color $primary
      height 3px

    .board-wrapper
      height 120px

    .board-st
      padding 8px 16px
      background-color $gray4
      color $sub3
      border-radius 24px
      margin 0 8px

    .board-selected
      color white
      background-color $primary

    .post-wrapper
      display grid
      grid-gap 30px 24px
      grid-template-columns repeat(3, 1fr)

    .card-img
      position relative
      width 100%
      height 250px
      border-radius 8px
      background-color white
  .mobile
    .post-banner
      position relative
      width 100%
      height 160px
      background-color white

    .board-cat-wrapper
      border-bottom 1px solid $gray1


    .board-cat-st
      padding 12px 16px
      position relative

    .board-cat-selected
      position absolute
      left 0
      bottom 0
      width 100%
      background-color $primary
      height 3px

    .board-wrapper
      height 78px

    .board-st
      padding 8px 16px
      background-color $gray4
      color $sub3
      border-radius 24px
      margin 0 4px

    .board-selected
      color white
      background-color $primary

    .post-wrapper
      display grid
      grid-gap 32px 12px
      grid-template-columns repeat(2, 1fr)

    .card-img
      position relative
      width 100%
      height 163px
      border-radius 8px
      background-color white

</style>
